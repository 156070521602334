import React, { forwardRef } from 'react';
import classes from './LabelButton.module.scss';
import { LabelButtonProps } from './LabelButton.types';
import Icon from '../../Icon/Icon';

/**
 * Set the useType, size, and sentiment to adjust the look of the button, the default look is selected in each case if not specified. 
   - The icon is optional.
     - Set the 'icon' prop to the name of the icon you want to use.
   - Use 'labelText for the button text.
     - If you want to use a custom component instead of icon/text, use the 'children' prop. this will override the icon and labelText props.
   - Add additional props which will be passed to the button element. Such as 'onClick', 'type', 'disabled', etc.
  */

const LabelButton = forwardRef(
  (
    {
      variantType = 'primary',
      size = 'default',
      sentiment = 'default',
      iconPosition = 'left',
      icon,
      labelText,
      disabled,
      children,
      className = '',
      ...props
    }: LabelButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const typeClass = `type-${variantType}`;
    const sizeClass = `size-${size}`;
    const iconPositionClass = icon ? `icon-${iconPosition}` : '';
    const sentimentClass = `sentiment-${sentiment}`;

    const defaultProps = {
      type: 'button',
      'data-testid': 'label-button-component',
    };
    const buttonProps = {
      ...defaultProps,
      ...props,
      disabled,
      ref,
    };

    const renderButtonContent = () => {
      if (children) return children;
      return (
        <>
          {icon && <Icon name={icon} size={size === 'small' ? 's' : 'default'} />}
          <span>{labelText}</span>
        </>
      );
    };

    return (
      <button
        className={[
          classes['LabelButton'],
          classes[typeClass],
          classes[sizeClass],
          classes[iconPositionClass],
          classes[sentimentClass],
          className,
        ].join(' ')}
        {...(buttonProps as React.ButtonHTMLAttributes<HTMLButtonElement>)}
      >
        {renderButtonContent()}
      </button>
    );
  }
);

export default LabelButton;
