import React from 'react';
import { BannerProps } from './Banner.types';
import Icon from '../Icon/Icon';
import IconButton from '../button/IconButton/IconButton';
import { ListOptions } from 'components/Icon/Icon.types';
import classes from './Banner.module.scss';
import TextLink from '..//TextLink/TextLink';
import LabelButton from '../button/LabelButton/LabelButton';

/**
 * Secondary description on Storybook Docs. I can have multiple lines and
   bullets!
  - The primary component has:
    - CSS modules
    - Tailwind styles
    - CSS variables
  - To make sure all different CSS channels are working
  */
const Banner: React.FC<BannerProps> = ({
  sentiment = 'info', // Set a default
  title,
  caption,
  className = '',
  action,
  closeFn,
  hideIcon = false,
  ...props
}) => {
  let icon = '';

  if (sentiment === 'warning') {
    icon = 'warning';
  } else if (sentiment === 'info' || sentiment === 'neutral') {
    icon = 'info';
  } else if (sentiment === 'negative') {
    icon = 'report';
  } else if (sentiment === 'success') {
    icon = 'check_circle';
  }

  const getIconSentiment = (sentiment) => {
    if (sentiment === 'warning') {
      return 'sui-text-warning-icon';
    } else if (sentiment === 'info') {
      return 'sui-text-info-icon';
    } else if (sentiment === 'negative') {
      return 'sui-text-negative-icon';
    } else if (sentiment === 'success') {
      return 'sui-text-positive-icon';
    } else if (sentiment === 'neutral') {
      return 'sui-text-neutral-icon';
    }
  };

  return (
    <div
      data-testid='banner-component'
      className={`${classes['banner']} ${classes[`banner--${sentiment}`]} ${className}`}
    >
      {!hideIcon && (
        <div data-testid='banner--icon-container' className={`${classes['banner--IconContainer']}`}>
          <Icon name={icon as ListOptions} size='l' />
        </div>
      )}

      <div data-testid='banner--content' className={`${classes['banner--content']}`}>
        <div
          data-testid='banner--content-title'
          className={`${classes['banner--content-title']} ${caption ? 'sui-mb-0.5' : ''}`}
        >
          {title}
        </div>
        <div data-testid='banner--content-caption' className={`${classes['banner--content-caption']}`}>
          {caption}
        </div>
      </div>

      {action ? (
        <>
          <div data-testid='banner--content-actions' className={`${classes['banner--content-actions']} t:sui-ml-1`}>
            {action.label && (
              <LabelButton
                variantType='tertiary'
                className={`${getIconSentiment(sentiment)} sui-hidden t:sui-block`}
                onClick={action.onClick}
              >
                {action.label}
              </LabelButton>
            )}
            <IconButton
              className={`${action.label ? 'sui-block t:sui-hidden' : ''} ${getIconSentiment(sentiment)}`}
              icon='chevron_right'
              onClick={() => (action.onClick ? action.onClick() : closeFn())}
            />
          </div>
        </>
      ) : null}

      {closeFn ? (
        <div data-testid='banner--content-actions' className={`${classes['banner--content-actions']} t:sui-ml-1`}>
          <IconButton className={`${getIconSentiment(sentiment)}`} icon='close' onClick={() => closeFn()} />
        </div>
      ) : null}
    </div>
  );
};

export default Banner;
