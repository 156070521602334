import React, { forwardRef } from 'react';
import classes from './Icon.module.scss';
import { IconProps } from './Icon.types';

/**
 * All icons we support are listed in the name dropdown. Choose a different one to see what each looks like.
 *
 * Figma for icons: https://www.figma.com/file/PVOAptZzuusZjr05qY0dJO/%F0%9F%92%BB-Web-App-%E2%80%94-Foundations?type=design&node-id=3%3A1014&mode=design&t=Jl3S9NBcxcb3yJ3P-1
 */
const Icon: React.ForwardRefRenderFunction<HTMLSpanElement, IconProps> = (
  { name, size = 'default', filled = false, className = '', ...props },
  ref
) => {
  const sizeClass = `sz-${size}`;

  return (
    <span
      ref={ref}
      data-testid='icon-component'
      className={[
        classes['Icon'],
        'material-symbols-rounded',
        classes[sizeClass],
        filled ? classes['filled'] : null,
        className,
      ].join(' ')}
      {...props}
    >
      {name}
    </span>
  );
};

export default forwardRef(Icon);
