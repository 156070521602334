import React from 'react';

/**
 * NOTE: Update Banner.test.tsx with all additions and changes!
 */
export type TooltipProps = {
  isVisible?: boolean;
  onVisibilityChange?: (isVisible: boolean) => void;
  showOnHover?: boolean;
  arrowPosition: ArrowPosition;
  showDismissButton?: boolean;
  anchorRef?: React.RefObject<HTMLElement>;
  /**
   * Children go between <Component>children</Component>
   */
  children: React.ReactNode;
  /**
   * Any other props to pass along (comment this out when running tests to catch prop errors)
   */
  [x: string]: any;
};

export enum ArrowPosition {
  TOP_LEFT = 'top left',
  TOP_RIGHT = 'top right',
  BOTTOM_LEFT = 'bottom left',
  BOTTOM_RIGHT = 'bottom right',
  LEFT_TOP = 'left top',
  LEFT_BOTTOM = 'left bottom',
  RIGHT_TOP = 'right top',
  RIGHT_BOTTOM = 'right bottom',
}
