import React from 'react';
import { ArrowPosition } from './Tooltip.types';

export const useTooltipPositionHelper = ({
  isVisible,
  arrowPosition,
  anchorRef,
  tooltipRef,
  showOnHover,
  onVisibilityChange,
  showDismissButton,
}): {
  includeTooltip: boolean;
  setIncludeTooltip: (boolean) => void;
} => {
  const [includeTooltip, setIncludeTooltip] = React.useState(null);

  const calculateTooltipPosition = () => {
    const anchorRect = anchorRef.current.getBoundingClientRect();
    switch (arrowPosition) {
      case ArrowPosition.TOP_LEFT:
        return {
          top: anchorRect.top + anchorRef.current.offsetHeight,
          left: anchorRect.left + anchorRef.current.offsetWidth / 2,
        };
      case ArrowPosition.TOP_RIGHT:
        return {
          top: anchorRect.top + anchorRef.current.offsetHeight,
          left: anchorRect.right - tooltipRef.current.offsetWidth - anchorRef.current.offsetWidth / 2,
        };
      case ArrowPosition.BOTTOM_LEFT:
        return {
          top: anchorRect.top - tooltipRef.current.offsetHeight,
          left: anchorRect.left + anchorRef.current.offsetWidth / 2,
        };
      case ArrowPosition.BOTTOM_RIGHT:
        return {
          top: anchorRect.top - tooltipRef.current.offsetHeight,
          left: anchorRect.right - tooltipRef.current.offsetWidth - anchorRef.current.offsetWidth / 2,
        };
      case ArrowPosition.LEFT_TOP:
        return {
          top: anchorRect.top,
          left: anchorRect.left + anchorRef.current.offsetWidth,
        };
      case ArrowPosition.LEFT_BOTTOM:
        return {
          top: anchorRect.bottom - tooltipRef.current.offsetHeight,
          left: anchorRect.left + anchorRef.current.offsetWidth,
        };
      case ArrowPosition.RIGHT_TOP:
        return {
          top: anchorRect.top,
          left: anchorRect.left - tooltipRef.current.offsetWidth,
        };
      case ArrowPosition.RIGHT_BOTTOM:
        return {
          top: anchorRect.bottom - tooltipRef.current.offsetHeight,
          left: anchorRect.left - tooltipRef.current.offsetWidth,
        };
      default:
        return { top: null, left: null };
    }
  };

  const updatePosition = () => {
    if (anchorRef?.current && tooltipRef?.current) {
      const updatedPosition = calculateTooltipPosition();
      tooltipRef.current.style.top = `${updatedPosition.top}px`;
      tooltipRef.current.style.left = `${updatedPosition.left}px`;
    }
  };

  const toggleTooltip = () => {
    setIncludeTooltip((prevVisible) => !prevVisible);
  };

  const removeTooltipMouseLeave = React.useCallback(
    (event: MouseEvent) => {
      const anchorRect = anchorRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const mouseX = event.clientX;
      const mouseY = event.clientY;

      if (
        (mouseX <= anchorRect.left ||
          mouseX >= anchorRect.right ||
          mouseY <= anchorRect.top ||
          mouseY >= anchorRect.bottom) &&
        (mouseX <= tooltipRect.left ||
          mouseX >= tooltipRect.right ||
          mouseY <= tooltipRect.top ||
          mouseY >= tooltipRect.bottom)
      ) {
        toggleTooltip();
      }
    },
    [anchorRef, tooltipRef]
  );

  const removeTooltipOutsideClick = React.useCallback(
    (event: MouseEvent) => {
      if (includeTooltip && tooltipRef.current && !tooltipRef.current.contains(event.target as HTMLElement)) {
        setIncludeTooltip(false);
      }
    },
    [includeTooltip, tooltipRef]
  );

  React.useEffect(() => {
    if (includeTooltip && anchorRef?.current && tooltipRef?.current) {
      updatePosition();
    }
  }, [includeTooltip, tooltipRef, anchorRef]);

  React.useEffect(() => {
    window.addEventListener('scroll', updatePosition);
    window.addEventListener('resize', updatePosition);
    if (showOnHover && anchorRef?.current) {
      anchorRef.current.addEventListener('mouseenter', toggleTooltip);
    }

    return () => {
      window.removeEventListener('scroll', updatePosition);
      window.removeEventListener('resize', updatePosition);
      if (showOnHover && anchorRef?.current) {
        anchorRef.current.removeEventListener('mouseenter', toggleTooltip);
      }
    };
  }, [anchorRef, showOnHover]);

  React.useEffect(() => {
    if (!showOnHover && isVisible) {
      setIncludeTooltip(true);
    }
  }, [isVisible, showOnHover]);

  React.useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      removeTooltipMouseLeave(event);
    };

    const handleMouseClick = (event: MouseEvent) => {
      removeTooltipOutsideClick(event);
    };

    if (anchorRef && showOnHover && includeTooltip) {
      window.addEventListener('mousemove', handleMouseMove);
    } else if (anchorRef && !showOnHover && includeTooltip && !showDismissButton) {
      window.addEventListener('click', handleMouseClick);
    }

    if (onVisibilityChange) {
      onVisibilityChange(includeTooltip);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('click', handleMouseClick);
    };
  }, [anchorRef, includeTooltip, removeTooltipMouseLeave, onVisibilityChange, showOnHover, showDismissButton]);

  return { includeTooltip, setIncludeTooltip };
};
