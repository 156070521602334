import React from 'react';

export const localStringToNumber = (s: string): number => {
  const numberRegEx = /[^0-9.-]+/g;
  const commasRegEx = /,/g;
  const cleaned = s.replace(numberRegEx, '').replace(commasRegEx, '');
  return Number(cleaned);
};

export const useOnClickOutside = (ref: React.RefObject<HTMLElement>, handler: (event: Event) => void) => {
  const listener = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
      handler(event);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mouseup', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mouseup', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const useDebounce = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = React.useState<T>(value);

  React.useEffect(() => {
    const id = window.setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      window.clearTimeout(id);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useBodyScrollLock = (isInitiallyLocked: boolean = false, scrollLockClass: string = 'scroll-lock') => {
  const [isBodyScrollLocked, setIsBodyScrollLocked] = React.useState(isInitiallyLocked);

  document.body.classList.toggle(scrollLockClass, isBodyScrollLocked);

  return { isBodyScrollLocked, setIsBodyScrollLocked };
};

/**
 * @name getClassName
 *
 * @description
 *  Takes a base className and calls stringifyArray to merge 'truthy' options into a single string it returns
 *
 * @example
 *  import { getClassName } from 'utils/helpers
 *
 *  getClassName(
 *    'BaseClassName',
 *    isInline && 'InlineModifier',
 *    isActive ? 'ActiveModifier' : 'InactiveModifier'
 *    'some-utility-modifier'
 * )
 *
 */
export const getClassName = (className, ...classModifiers) => {
  return [className, ...classModifiers].filter(Boolean).join(' ');
};

export const isSafari = () => {
  return /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
};
