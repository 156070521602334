import React from 'react';
import classes from './RadioTab.module.scss';
import { RadioTabProps } from './RadioTab.types';
import Icon from '../Icon/Icon';

/**
 * Used in a group as a tab. Pass in the "name" prop to group radios together. Then each radio needs a unique "value" prop.
  - inputProps: use the `inputProps` object to pass props to the input element such as onChange, onBlur, etc.
  - labelProps: use the `labelProps` object to pass props to the label element such as onClick, etc.
  - use a label prop to pass in a string or JSX element for the label
  */
const RadioTab: React.FC<RadioTabProps> = ({
  label,
  name,
  value,
  disabled,
  checked,
  className = '',
  onChange,
  inputProps = {},
  labelProps = {},
  ...props
}) => {
  const elementId = `${name}-${value}`;
  return (
    <div
      data-testid='radio-tab-component'
      className={[classes['radio-tab'], disabled ? classes['disabled'] : '', className].join(' ')}
      {...props}
    >
      <input {...{ type: 'radio', checked, onChange, disabled, name, value, id: elementId, ...inputProps }} />
      <label {...{ htmlFor: elementId, ...labelProps }}>
        <Icon name='check' className={classes['icon']} />
        {label}
      </label>
    </div>
  );
};

export default RadioTab;
