import React from 'react';
import classes from './IconButton.module.scss';
import { IconButtonProps } from './IconButton.types';
import Icon from '../../Icon/Icon';

const COMPONENT_CLASS = 'IconButton';

/**
 * Secondary description on Storybook Docs. I can have multiple lines and
   bullets!
  - The primary component has:
    - CSS modules
    - Tailwind styles
    - CSS variables
  - To make sure all different CSS channels are working
  */
const IconButton: React.FC<IconButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  icon,
  border,
  disabled,
  sentiment = 'default',
  size = 'default',
  iconSize = 'default',
  className = '',
  ...props
}) => {
  const sentimentClass = `sentiment-${sentiment}`;
  const sizeClass = `size-${size}`;

  const defaultProps = {
    type: 'button',
    'data-testid': 'icon-button-component',
  };
  const buttonProps = {
    ...defaultProps,
    ...props,
    disabled,
  };

  const renderButtonContent = () => {
    return (
      <>
        <Icon name={icon} size={size === 'small' ? 's' : 'default'} />
      </>
    );
  };

  const classNames = [classes[COMPONENT_CLASS], classes[sentimentClass], classes[sizeClass], className];
  if (border) {
    classNames.push(classes['bordered']);
  }

  return (
    <button className={classNames.join(' ')} {...(buttonProps as React.ButtonHTMLAttributes<HTMLButtonElement>)}>
      {renderButtonContent()}
    </button>
  );
};

export default IconButton;
