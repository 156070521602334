import React from 'react';
import classes from './ModalContainer.module.scss';
import { ModalContainerProps } from './ModalContainer.types';
import IconButton from '../button/IconButton/IconButton';
import { useBodyScrollLock } from '../../utils';

/**
 * Secondary description on Storybook Docs. I can have multiple lines and
   bullets!
  - The primary component has:
    - CSS modules
    - Tailwind styles
    - CSS variables
  - To make sure all different CSS channels are working
  */
const ModalContainer: React.FC<ModalContainerProps> = ({
  size = 'md', // Set a default
  isOpen = false,
  onClose = () => {},
  children,
  closeButton = true,
  className = '',
  disableOverlayClose = false,
  ...props
}) => {
  const [_isOpen, _setIsOpen] = React.useState(isOpen);
  const [_animatingOut, _setAnimatingOut] = React.useState(false);

  const { setIsBodyScrollLocked } = useBodyScrollLock();

  React.useEffect(() => {
    if (isOpen) {
      _setIsOpen(true);
      setIsBodyScrollLocked(true);
    } else if (!isOpen && _isOpen && !_animatingOut) {
      _setAnimatingOut(true);
    }
  }, [isOpen, _isOpen, _animatingOut]);

  return _isOpen ? (
    <div
      className={`${classes[`modal`]} ${!_animatingOut ? classes[`modal-fade-in`] : classes[`modal-fade-out`]}`}
      onAnimationEnd={() => {
        if (_animatingOut) {
          onClose();
          _setIsOpen(false);
          _setAnimatingOut(false);
          setIsBodyScrollLocked(false);
        }
      }}
    >
      <div
        data-testid='modal-container-component'
        className={[classes['ModalContainer'], classes[`ModalContainer--size-${size}`], className].join(' ')}
        {...props}
      >
        {closeButton && (
          <IconButton
            className={classes['ModalContainer__close-button']}
            icon='close'
            sentiment='neutral'
            size='large'
            onClick={onClose}
          />
        )}
        <div className={classes['modal-content']}>{children}</div>
      </div>
      <div className={classes['modal-screen-overlay']} onClick={!disableOverlayClose ? onClose : null} />
    </div>
  ) : null;
};

export default ModalContainer;
